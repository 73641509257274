import React from "react"
import styled from "styled-components"

class PricesBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      btcPrice: "",
      ethPrice: "",
      xrpPrice: "",
      bchPrice: "",
      bsvPrice: "",
      ltcPrice: "",
      eosPrice: "",
      bnbPrice: "",
      xtzPrice: "",
      adaPrice: "",
      title: "",
      announcements: [],
      place: 0,
      ranks: 0,
    }
  }

  componentDidMount() {
    fetch(
      "https://min-api.cryptocompare.com/data/pricemulti?fsyms=BTC,ETH,XRP,BCH,BSV,LTC,EOS,BNB,XTZ,ADA&tsyms=USD&api_key=de95ede36af04390324f1d05bbbac53d9c49bae0dc13a3af7458185f7d910e8b"
    )
      // fetch("https://coinlib.io/api/v1/coin?key=b3c0b86e83461c72&pref=USD&symbol=BTC,ETH,XRP,BCH,XLM")
      // ("https://api.coinmarketcap.com/v2/ticker/?limit=10")
      // fetch("https://pro-api.coinmarketcap.com/v1/cryptocurrency/quotes/latest?CMC_PRO_API_KEY=4e5ab13e-16e1-493a-bc9a-cad9614fbe5f&id=1")
      .then(resp => resp.json())
      .then(jsonResponse => {
        let btcPrice = jsonResponse.BTC.USD
        let ethPrice = jsonResponse.ETH.USD
        let xrpPrice = jsonResponse.XRP.USD
        let bchPrice = jsonResponse.BCH.USD
        let bsvPrice = jsonResponse.BSV.USD
        let ltcPrice = jsonResponse.LTC.USD
        let eosPrice = jsonResponse.EOS.USD
        let bnbPrice = jsonResponse.BNB.USD
        let xtzPrice = jsonResponse.XTZ.USD
        let adaPrice = jsonResponse.ADA.USD
        this.setState({
          btcPrice,
          ethPrice,
          xrpPrice,
          bchPrice,
          bsvPrice,
          ltcPrice,
          eosPrice,
          bnbPrice,
          xtzPrice,
          adaPrice,
        })
      })
  }

  render() {
    const {
      btcPrice,
      ethPrice,
      xrpPrice,
      bchPrice,
      bsvPrice,
      ltcPrice,
      eosPrice,
      bnbPrice,
      adaPrice,
      xtzPrice,
      place,
      time,
    } = this.state
    return (
      <Main>
        <Side>
          <CurrentPriceHeader>
            <CurrentPriceTitle>Current Prices:</CurrentPriceTitle>
          </CurrentPriceHeader>

          <PriceContainer>
            <CurrentPriceName>Bitcoin</CurrentPriceName>
            <CurrentPrice>${btcPrice}</CurrentPrice>
          </PriceContainer>

          <PriceContainer>
            <CurrentPriceName>ETH</CurrentPriceName>
            <CurrentPrice>${ethPrice}</CurrentPrice>
          </PriceContainer>

          <PriceContainer>
            <CurrentPriceName>XRP</CurrentPriceName>
            <CurrentPrice>${xrpPrice}</CurrentPrice>
          </PriceContainer>

          <PriceContainer>
            <CurrentPriceName>BCH</CurrentPriceName>
            <CurrentPrice>${bchPrice}</CurrentPrice>
          </PriceContainer>

          <PriceContainer>
            <CurrentPriceName>BSV</CurrentPriceName>
            <CurrentPrice>${bsvPrice}</CurrentPrice>
          </PriceContainer>

          <PriceContainer>
            <CurrentPriceName>LTC</CurrentPriceName>
            <CurrentPrice>${ltcPrice}</CurrentPrice>
          </PriceContainer>

          <PriceContainer>
            <CurrentPriceName>EOS</CurrentPriceName>
            <CurrentPrice>${eosPrice}</CurrentPrice>
          </PriceContainer>

          <PriceContainer>
            <CurrentPriceName>BNB</CurrentPriceName>
            <CurrentPrice>${bnbPrice}</CurrentPrice>
          </PriceContainer>

          <PriceContainer>
            <CurrentPriceName>XTZ</CurrentPriceName>
            <CurrentPrice>${xtzPrice}</CurrentPrice>
          </PriceContainer>
          <PriceContainer>
            <CurrentPriceName>ADA</CurrentPriceName>
            <CurrentPrice>${adaPrice}</CurrentPrice>
          </PriceContainer>
        </Side>
        <iframe
          title="Title"
          data-aa="1323916"
          src="//ad.a-ads.com/1323916?size=320x50"
          scrolling="no"
          style={{
            width: 320,
            height: 50,
            border: 0,
            padding: 0,
            overflow: "hidden",
            borderRadius: 5,
            marginTop: 20,
          }}
          allowtransparency="true"
        />
        <a href="http://cointiply.com/r/J7dGl">
          <img
            title="Join Cointiply to Earn Free Bitcoin!"
            alt="Earn Free Bitcoin"
            src="https://cointiply.com/img/promo/banners/3_300x250.gif"
          />
        </a>
      </Main>
    )
  }
}

export default PricesBox

const Main = styled.div`
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 30%;
  min-width: 320px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  overflow: hidden;
  @media only screen and (min-width: 320px) and (max-width: 820px) {
    display: none;
  }
`

const Side = styled.div`
  background-color: white;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 5px;
  width: 100%;
  display: flex;
  overflow: hidden;
  @media only screen and (min-width: 320px) and (max-width: 820px) {
    display: none;
  }
`

const CurrentPriceHeader = styled.div`
  background-color: rgba(22, 82, 240, 1);
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  display: flex;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  padding-top: 10px;
  padding-bottom: 10px;
`

const PriceContainer = styled.div`
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  display: flex;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
`

const CurrentPriceTitle = styled.span`
  font-family: proxima-nova, helvetica neue, Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: white;
  margin-left: 15px;
  letter-spacing: 0.075em;
`

const CurrentPriceName = styled.span`
  color: black;
  font-size: 16px;
  padding: 15px;
  font-weight: bold;
  font-family: proxima-nova, helvetica neue, Helvetica, Arial, sans-serif;
  text-transform: uppercase;
`

const CurrentPrice = styled.span`
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  padding: 15px;
  font-weight: normal;
  letter-spacing: 0.075em;
  text-transform: uppercase;
  font-family: proxima-nova, helvetica neue, Helvetica, Arial, sans-serif;
  text-transform: uppercase;
`
