import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const ListContainer = ({
  Logo,
  Title,
  Value,
  Ticker,
  Address,
  From,
  Path,
  ImageAlt,
}) => (
  <Main>
    <LeftRow>
      <LogoContainer alt={ImageAlt} src={Logo} />
      <TitleContainer>
        <ListTitle>
          {Title}
          <ListTicker> ({Ticker})</ListTicker>
        </ListTitle>
        <ListValue>
          <ListValueTitle>Value:</ListValueTitle>
          {Value}
        </ListValue>
        <FromText>{From}</FromText>
      </TitleContainer>
    </LeftRow>
    <ViewContainer to={Path}>
      <ViewText>View</ViewText>
    </ViewContainer>
  </Main>
)

export default ListContainer

const Main = styled.div`
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  &:hover {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  }
  transition-delay: 0.05s;
  transition-duration: 0.3s;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  @media only screen and (min-width: 320px) and (max-width: 820px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`

const LogoContainer = styled.img`
  width: 60px;
  height: 60px;
  margin: 20px;

  @media only screen and (min-width: 320px) and (max-width: 820px) {
    width: 40px;
    height: 40px;
  }
`

const LeftRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
`

const ViewContainer = styled(Link)`
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  aling-items: center;
  justify-content: center;
  padding: 10px;
  background-color: rgba(22, 82, 240, 1);
  margin-right: 20px;
`

const ViewText = styled.span`
  font-family: proxima-nova, helvetica neue, Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: white;
`

const ListTitle = styled.h1`
  font-family: proxima-nova, Avenir, sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: rgb(69, 75, 92);
  text-align: left;
  margin: 0px;
  @media only screen and (min-width: 320px) and (max-width: 820px) {
    font-size: 16px;
  }
`

const ListTicker = styled.span`
  font-family: proxima-nova, Avenir, sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: rgba(69, 75, 92, 0.6);
  text-align: left;
  @media only screen and (min-width: 320px) and (max-width: 820px) {
    font-size: 14px;
  }
`

const ListValueTitle = styled.span`
  font-family: proxima-nova, Avenir, sans-serif;
  font-size: 16px;
  font-weight: 200;
  color: rgb(69, 75, 92);
  text-align: left;
  @media only screen and (min-width: 320px) and (max-width: 820px) {
    font-size: 14px;
  }
`

const ListValue = styled.span`
  font-family: proxima-nova, Avenir, sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: rgb(69, 75, 92);
  text-align: left;
  @media only screen and (min-width: 320px) and (max-width: 820px) {
    font-size: 14px;
  }
`

const FromText = styled.span`
  font-family: proxima-nova, Avenir, sans-serif;
  font-size: 12px;
  font-weight: 200;
  color: rgba(69, 75, 92, 0.4);
  text-align: left;
  @media only screen and (min-width: 320px) and (max-width: 820px) {
    font-size: 10px;
  }
`
